<template>
  <div>
    <div class="generateReportForInsuranceOuterContainer">


      <div class="travelReportOuterItem" v-for="travelOrderItem in statisticsData[0].data">
        <div class="reportForInsuranceContainer" v-if="volunteerData && statisticsData">
          <div class="travelReportInnerItem">
            <div class="row travelReportRow">
              <div class="col-md-12">
                <h3>Nalog za službeno potovanje</h3>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                Nalog za službeno potovanje št.
                <b>{{ moment(travelOrderItem.date).format('DD-MM-YYYY') }}-{{
                    travelOrderItem.volunteer.id
                  }}-{{ travelOrderItem.id }}</b>
              </div>
              <div class="col-md-6">
                Datum: <b>{{ moment(travelOrderItem.date).format('DD. MM. YYYY') }}</b>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                Odrejam, da potuje: <b>{{ volunteerData.first_name }}
                {{ volunteerData.last_name }}</b>.
              </div>
              <div class="col-md-6">
                Na delovnem mestu: <b>prostovoljec</b>.
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-12">
                Po nalogu: <b>Zavoda Sopotniki</b>.
              </div>
              <div class="col-md-12">
                Iz naslova:
                <b>{{ volunteerData.addresses.address_name }}<span v-if="volunteerData.addresses.address_name">,</span>
                  {{ volunteerData.addresses.quick_search }}</b>.
              </div>

              <div class="col-md-12">
                Na naslov:
                <b v-if="!volunteerData.businessCarLocationAddress">{{
                    volunteerData.units.addresses.address_name
                  }}<span
                    v-if="volunteerData.units.addresses.address_name">,</span>
                  {{ volunteerData.units.addresses.quick_search }}</b>
                <b v-if="volunteerData.businessCarLocationAddress">{{
                    volunteerData.businessCarLocationAddress.address_name
                  }}<span
                    v-if="volunteerData.businessCarLocationAddress.address_name">,</span>
                  {{ volunteerData.businessCarLocationAddress.quick_search }}</b>.
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-12">
                Z nalogo / namenom poti: <b>{{ travelOrderItem.type.name }}</b>.
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-6">
                Plačnik potnih stroškov: <b v-if="volunteerData.travelCostsRefund === 1">Zavod Sopotniki</b><b
                v-if="volunteerData.travelCostsRefund != 1">potnih stroškov ni oz. jih ni potrebno izplačati</b>.
              </div>
              <div class="col-md-6">
                Višina dnevnice: <b>/</b>
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-6">
                Posebni dodatki: <b>/</b>
              </div>
              <div class="col-md-6">
                Odrejam plačilo predujma v znesku: <b>/</b>
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-12">
                Prejel dne: <b>{{ moment(travelOrderItem.date).format('DD-MM-YYYY') }}</b>.
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-6">
                Odredbodajalec: <br/><b>Marko Zevnik</b> <br/> <img width="150" style="margin-right: -100px;"
                                                                    :src="require('@/assets/images/content/business_letters/marko.png')"/>.
              </div>
              <div class="col-md-6">
                Prejemnik: <br/><b>{{ volunteerData.first_name }}
                {{ volunteerData.last_name }}</b><br/><br/><br/><br/>
                <div style="width: 100%; height: 1px; background: #e8e7e8;"></div>
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-12"><br/>
                <div style="width: 100%; height: 1px; background: #e8e7e8;"></div>
              </div>
            </div>

          </div>

          <div class="travelReportReceiptItem">
            <div class="row travelReportRow">
              <div class="col-md-12">
                <h3>Račun potnih stroškov</h3>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                Naziv predlagatelja: <b>Zavod Sopotniki</b>.
              </div>
              <div class="col-md-6">
                Naslov predlagatelja: <b>Kraška ulica 2, 6210 Sežana, Slovenija</b>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                Datum odhoda: <b>{{ moment(travelOrderItem.date).format('DD. MM. YYYY') }}</b>.
              </div>
              <div class="col-md-6">
                Datum prihoda: <b>{{ moment(travelOrderItem.date).format('DD. MM. YYYY') }}</b>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-12">
                <h5>Prevozni stroški</h5>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                Kilometrina: <b>{{ volunteerData.dailyTravelDistance }} &times; 0.21 EUR</b>.
              </div>
              <div class="col-md-6">
                Znesek kilometrine: <b>{{ volunteerData.dailyTravelCost }} EUR</b>.
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-12">
                <h5>Drugi stroški</h5>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                Parkirnine: <b>/</b>
              </div>
              <div class="col-md-6">
                Nočitve: <b>/</b>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                Cestnine: <b>/</b>
              </div>
              <div class="col-md-6">
                Vstopnice: <b>/</b>
              </div>
            </div>
            <div class="row travelReportRow">
              <div class="col-md-6">
                <div style="border: 1px solid #e8e7e8; border-radius: 4px; padding: 4px;">
                  Skupni znesek potnega naloga: <br/><b
                  v-if="volunteerData.travelCostsRefund === 1">{{ volunteerData.dailyTravelCost }} EUR</b><b
                  v-if="volunteerData.travelCostsRefund !== 1">0,00 EUR (potnih stroškov ni oz. jih ni potrebno
                  izplačati)</b>.
                </div>
              </div>
              <div class="col-md-6">
                Ostane za izplačilo/vračilo: <b
                v-if="volunteerData.travelCostsRefund === 1">{{ volunteerData.dailyTravelCost }} EUR</b><b
                v-if="volunteerData.travelCostsRefund !== 1">0,00 EUR (potnih stroškov ni oz. jih ni potrebno
                izplačati)</b>.
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-6">
                Predlagatelj računa: <br/><b>Marko Zevnik</b><br/>
                <img width="150" style="margin-right: -100px;"
                     :src="require('@/assets/images/content/business_letters/marko.png')"/>.
              </div>
              <div class="col-md-6">
                Prejemnik: <br/><b>{{ volunteerData.first_name }}
                {{ volunteerData.last_name }}</b><br/><br/><br/><br/>
                <div style="width: 100%; height: 1px; background: #e8e7e8;"></div>
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-12">
                Kraj in datum: <b>Sežana, {{ moment(travelOrderItem.date).format('DD. MM. YYYY') }}</b>.
              </div>
            </div>

            <div class="row travelReportRow">
              <div class="col-md-12"><br/>
                <div style="width: 100%; height: 1px; background: #e8e7e8;"></div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="travelReportSummary" v-if="statisticsData && volunteerData">
        <div class="reportForInsuranceContainer" v-if="volunteerData && statisticsData">
          <div class="row travelReportRow">
            <div class="col-md-12">
              <h3>Povzetek za <span v-if="moment(startDate).format('MM') === moment(endDate).format('MM')">{{
                  moment(startDate).format('MMMM')
                }} {{ moment(startDate).format('YYYY') }}</span>
                <span v-if="moment(startDate).format('MM') !== moment(endDate).format('MM')">obdobje: {{
                    moment(startDate).format('MMMM')
                  }} {{ moment(startDate).format('YYYY') }} - {{
                    moment(endDate).format('MMMM')
                  }} {{ moment(endDate).format('YYYY') }}</span>

              </h3>
            </div>
          </div>
          <div class="row travelReportRow">
            <div class="col-md-12">

              <table class="table">
                <thead>
                <tr>
                  <th class="p-1">#</th>
                  <th class="p-1">Prejemnik</th>
                  <th class="p-1">Datum potnega naloga</th>
                  <th class="p-1">Št. kilometrov</th>
                  <th class="p-1">Vrednost na km</th>
                  <th class="p-1">Znesek potnih stroškov</th>
                </tr>
                </thead>
                <tbody>

                <tr v-for="(travelOrderItem, index) in statisticsData[0].data">
                  <td class="p-1">{{ index + 1 }}</td>
                  <td class="p-1">{{ volunteerData.first_name }} {{ volunteerData.last_name }}</td>
                  <td class="p-1">{{ moment(travelOrderItem.date).format('DD. MM. YYYY') }}</td>
                  <td class="p-1">{{ volunteerData.dailyTravelDistance }}</td>
                  <td class="p-1">0,21 eur</td>
                  <td class="p-1">
                    <span
                      v-if="volunteerData.travelCostsRefund === 1">{{ volunteerData.dailyTravelCost }} eur</span>
                    <span v-if="volunteerData.travelCostsRefund !== 1"><em>potnih stroškov ni oz. jih ni potrebno izplačati</em></span>
                  </td>
                </tr>

                </tbody>
                <tfoot>
                <tr>
                  <th class="p-1 text-right" colspan="5"><b>SKUPAJ:</b></th>
                  <th class="p-1"><b>{{
                      parseFloat(Number(volunteerData.dailyTravelCost) * statisticsData[0].data.length).toFixed(2)
                    }} EUR</b></th>
                </tr>
                </tfoot>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

</template>

<script>
import Vue from "vue";
import VueQrcode from "vue-qrcode";

export default {
  name: 'StatisticsOnVolunteersTravelCosts',

  components: {
    VueQrcode
  },
  data() {
    return {
      volunteerId: null,
      volunteerData: null,
      statisticsData: null,
      startDate: null,
      endDate: null,
      unitId: null,
    }
  },

  computed: {},

  async mounted() {
    const _this = this;


    if (this.$route.query.jwt) {
      Vue.prototype.$http.interceptors.request.use(
        config => {
          const token = this.$route.query.jwt;
          if (token) {
            if (config.url.includes('https://api.sopotniki.org/')) {
              config.headers.common["Authorization"] = 'Bearer ' + token;
            } else {
              config.headers.common["Authorization"] = token;
            }
          }
          return config;
        },
        error => {
          return Promise.reject(error);
        }
      );

    }

    //_this.donorData = _this.$route.params.donorData;
    _this.volunteerId = Number(_this.$route.query.volunteerId) || _this.$route.params.volunteerId;
    _this.startDate = Number(_this.$route.query.startDate) || _this.$route.params.startDate;
    _this.endDate = Number(_this.$route.query.endDate) || _this.$route.params.endDate;
    _this.unitId = Number(_this.$route.query.unitId)|| _this.$route.params.unitId;
    await _this.getDailyReports();
    await _this.getVolunteerData();
  },
  methods: {
    async getDailyReports() {
      const _this = this;
      _this.$vs.loading();

      const unit = _this.unitId;
      const url = `${Vue.prototype.$config.api.sopotnikiVPS}daily-reports?unit=${unit}&startDate=${_this.moment(_this.startDate).startOf('month').valueOf()}&endDate=${_this.moment(_this.endDate).endOf('month').valueOf()}`;

      await Vue.prototype.$http.get(url)
        .then((res) => {
          const dailyReportsData = _.filter(res.data.data, ['volunteer.id', _this.volunteerId]);

          _this.statisticsData = _.chain(dailyReportsData)
            .groupBy('volunteer.id')
            .map((value, key) => {
              return {volunteer: key, volunteerData: value[0].volunteer, data: value}
            })
            .value();

          console.log("_this.statisticsData", _this.statisticsData);
          _this.$vs.loading.close();
        })
        .catch((error) => {
          console.log('error', error);
          _this.$vs.loading.close();
        });
    },
    async getVolunteerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.volunteerId)
        .then((res) => {
          _this.volunteerData = res.data.data;
          _this.$vs.loading.close();
          console.log("volunteersData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
    async getUnitManagerData() {
      const _this = this;
      _this.$vs.loading();

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'volunteers/' + _this.unitManagerId)
        .then((res) => {
          _this.unitManagerData = res.data.data;
          _this.$vs.loading.close();
          console.log("unitManagerData: ", res.data.data);
        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },
  }
}
</script>

<style lang="scss">
html, body {
  width: 210mm;
  height: 297mm;
}

@page {
  size: A4;
  margin: 0;
  -webkit-print-color-adjust: exact !important;
  page-break-before: auto;
  page-break-after: auto;
  page-break-inside: avoid;
}

.generateReportForInsuranceOuterContainer {
  font-family: 'Open Sans' !important;
  color: black !important;

  .reportForInsuranceContainer {
    position: relative;
    overflow: hidden;
    width: 210mm;
    height: 296mm;
    background-size: contain !important;
    -webkit-print-color-adjust: exact !important;
    padding: 5% 5% 5% 5% !important;
    display: block !important;
    color: black !important;

    .bgImg {
      width: 210mm;
      height: 296mm;
      position: absolute;
      z-index: -1;
      left: 0px;
      top: 0px;
    }

    .reportDateContainer {
      position: absolute;
      left: 387px;
      top: 130px;

      .month {
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
        display: inline-block;
      }

      .yearLabel {
        font-size: 18px;
        padding: 0px 20px;
        color: #000000;
        text-transform: uppercase;
        display: inline-block;
      }

      .year {
        font-size: 18px;
        color: #000000;
        text-transform: uppercase;
        text-decoration: underline;
        font-weight: bold;
        display: inline-block;
      }

    }

    .unitInfoContainer {
      position: absolute;
      left: 74px;
      top: 50px;

      .unitInfoLabel {
        font-size: 20px;
        font-weight: bold;
        line-height: 30px;
        padding: 0px 5px 0px 20px;
        color: #000000;
        text-transform: uppercase;
        display: inline-block;
      }

      .unitInfo {
        font-size: 14px;
        line-height: 30px;
        color: #000000;
        text-transform: uppercase;
        font-weight: bold;
        display: inline-block;
      }
    }

    .volunteersContainer {
      position: absolute;
      left: 95px;
      top: 120px;
      font-size: 16px;
      color: #000000;
      width: 80%;

    }

    .date {
      position: absolute;
      left: 210px;
      top: 912px;
      font-size: 16px;
      line-height: 30px;
      color: #000;

    }

    .podpis {
      width: 300px;
      position: absolute;
      margin-top: -70px;
      margin-left: 100px;
      z-index: 2;
    }

    .zig {
      width: 200px;
      position: absolute;
      margin-top: -10px;
      margin-left: 180px;
      z-index: 1;
    }
  }

  .panel {
    border: none !important;
    background: none !important;
  }

  h1 {
    margin: 10px 0px;
    font-size: 18px;
    font-weight: bold;
  }

  h2 {
    margin: 10px 0px;
    font-size: 18px;
  }

  h3 {
    margin: 10px 0px;
    font-size: 18px !important;
  }

  h4, h5 {
    margin: 5px 0px;
    font-size: 16px !important;
  }

  .travelReportOuterItem {
    break-inside: avoid;
  }

  .travelReportRow {
    padding: 5px 0px !important;
    margin: 0px !important;
    float: none;
    clear: both;

    .col-md-6 {
      width: 50% !important;
      padding: 0px 5%;
      overflow: hidden;
      margin: 0px !important;
      float: left;
    }

    .col-md-12 {
      padding: 0px 5%;
      width: 100% !important;
      overflow: hidden;
      margin: 0px !important;
      float: left;
    }
  }

  .travelReportReceiptItem hr {
    display: none !important;
  }
}
</style>
